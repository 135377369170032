export const API_URL = 'https://api.app.betoncablo.it'
// export const API_URL = 'https://api.staging.betoncablo.f2.net'

export const AUDIENCE_URL = "https://api.app.betoncablo.it"

export const DOMAIN_ISSUER = "betoncablo.eu.auth0.com"
// export const DOMAIN_ISSUER = 'betoncablo.eu.auth0.com'

export const CLIENT_ID = "q4TthP9uMZnKu95yWXs4QrVXpUhvbpdf"
// export const CLIENT_ID = 'q4TthP9uMZnKu95yWXs4QrVXpUhvbpdf'

export const REDIRECT_URI = 'https://app.betoncablo.it'

export const GIORNI_ANTICIPO_MIN = 4
